import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { DealerProfile, TargetPayApi } from "../../../utils/UtilTarget";
import { isEmpty } from "../../../utils/Util";
import { Download as DownloadIcon } from "@mui/icons-material";
import moment from "moment";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface Report {
  reportId: string;
  fileName: string;
  startDate: string;
  endDate: string;
  generatedOn: string;
}

export default function DailyReconciliationReport() {
  const [reports, setReports] = React.useState<Report[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [ReportDownloading, SetReportDownloading] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const isAppFeaturesLoaded = useAppSelector(
    (state: RootState) => state.appFeatures.isLoaded
  );

  useEffect(() => {
    document.title = "Daily Reconciliation Report";
  }, []);

  // Fetch Daily Reconciliation Reports
  useEffect(() => {
    const GetDailyReconciliationReports = async () => {
      try {
        setLoading(true);

        await GetDealerReports();

        setLoading(false);
      } catch (error) {
        console.error("Error fetching Reports:", error);
      }
    };

    GetDailyReconciliationReports();
  }, []);

  async function GetDealerReports() {
    let dealerId: any = await DealerProfile("dealerId");

    const res = await TargetPayApi(
      `GetReportData?dealerId=${dealerId}`,
      null,
      "get",
      "Report"
    );

    if (!isEmpty(res)) {
      setReports(res.data);
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = async (reportId: string) => {
    try {
      SetReportDownloading(reportId); // Show spinner for this specific report
      // Fetch the URL
      const url = await TargetPayApi(`${reportId}`, null, "get", "Report");

      if (!isEmpty(url)) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${reportId}.csv`); // or extract filename from response
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("URL is empty");
      }
    } catch (error) {
      console.error("Error downloading the report:", error);
    } finally {
      SetReportDownloading(""); // Hide spinner after download completes
    }
  };

  return isAppFeaturesLoaded && localStorage.getItem("report_feature_flag") === "false" ? (
    <Box>
      <Navigate to="/401" />
    </Box>
  ) : (
    <Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ padding: "0px", paddingRight: "0px" }}
        >
          <Box>
            Please wait. Processing.....
            <CircularProgress></CircularProgress>{" "}
          </Box>
        </Box>
      ) : (
        <>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={0}
            >
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Daily Reconciliation Report
              </Typography>
            </Stack>
          </Box>
          Transaction details for all settled payments paid out to your bank
          account.
          <Paper>
            <TableContainer
              sx={{
                height: "74vh",
                marginTop: "15px",
              }}
              style={{
                borderBottom: "1px solid #cfcfcf",
              }}
            >
              <Table size="small" className="sticky-header sortable">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", width: "45%" }}>
                      Filename
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "15%" }}>
                      Generated on
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "15%" }}>
                      Payout date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      Download
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(reports) ? (
                    reports
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((report, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ width: "45%" }}>
                            <Tooltip title={`Download ${report.fileName}`}>
                              <Link
                                href="#"
                                underline="hover"
                                onClick={() => handleDownload(report.reportId)}
                                sx={{
                                  pointerEvents:
                                    ReportDownloading === report.reportId
                                      ? "none"
                                      : "auto",
                                  color:
                                    ReportDownloading === report.reportId
                                      ? "grey"
                                      : "primary.main",
                                  cursor:
                                    ReportDownloading === report.reportId
                                      ? "default"
                                      : "pointer",
                                }}
                              >
                                {report.fileName}
                              </Link>
                            </Tooltip>
                          </TableCell>
                          <TableCell sx={{ width: "15%" }}>
                            {moment
                              .utc(report.generatedOn)
                              .local()
                              .format("M/D/YYYY h:mm A")}
                          </TableCell>
                          <TableCell sx={{ width: "15%" }}>
                            {moment
                              .utc(report.startDate)
                              .local()
                              .format("M/D/YYYY")}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", width: "10%" }}>
                            <Tooltip title={`Download ${report.fileName}`}>
                              <IconButton
                                href="#"
                                aria-label="download"
                                onClick={() => handleDownload(report.reportId)}
                                disabled={ReportDownloading === report.reportId}
                              >
                                {ReportDownloading === report.reportId ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <DownloadIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>No record found.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" alignItems="center" justifyContent="end">
              <Table>
                <TableBody>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 15, 25]}
                      //component="div"
                      count={reports.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Paper>
        </>
      )}
    </Box>
  );
}
